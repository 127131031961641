<template>
    <div class="login-register">
        <AuthorizationForms
            :form-id="currentFormId"
            :initial-email="initialEmail"
            :with-close-button="false"
            :can-back-to-magic-link-sent-information="true"
            :with-register-benefits="withRegisterBenefits"
            v-on="authorizationFormsListeners"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import { TOPICS } from '@loyalty-club/models/PubSub';

import { META_ROBOTS_INDEX_FOLLOW } from '@configs/seo';
import { PAYMENT_AND_SHIPPING_METHODS_WHITELIST } from '@configs/checkout-summary';
import {
    REDIRECT_TO_CUSTOMER_PAGE_AFTER_LOGIN,
    REDIRECT_TO_INVITE_FRIEND_PAGE_AFTER_LOGIN,
} from '@configs/storage';
import {
    MODAL_ACCOUNT_VERIFICATION,
    MODAL_ACCOUNT_VERIFICATION_TYPE,
    MODAL_SNIPER_LINK,
    MODAL_SNIPER_LINK_EMAIL,
    MODAL_NEWSLETTER_CONFIRMATION,
    JOIN_TO_LOYALTY_CLUB_MODAL,
} from '@configs/modals';
import { ACCOUNT_VERIFICATION_STATES } from '@configs/account-verification';
import { DAYS_FOR_RETURNS } from '@localeConfig/keys';

import { AUTHORIZATION_FORMS_IDS } from '@types/CustomerModal';

import AnalyticsEvents from '@mixins/AnalyticsEvents';

import {
    USER_LOGIN_SUCCESS,
    LOGIN_PAGE_RESET_PASSWORD_CLICK,
    RESET_PASSWORD_PAGE_RESET_PASSWORD_SENT,
    LOGIN_PAGE_REGISTER_CLICK,
    REGISTER_PAGE_LOGIN_CLICK,
    RESET_PASSWORD_PAGE_BACK_TO_LOGIN_PAGE_CLICK,
    REGISTER_PAGE_CONTINUE_WITH_FACEBOOK_CLICK,
    LOGIN_PAGE_CONTINUE_WITH_FACEBOOK_CLICK,
    REGISTER_PAGE_CONTINUE_WITH_GOOGLE_CLICK,
    LOGIN_PAGE_CONTINUE_WITH_GOOGLE_CLICK,
    REGISTER_PAGE_REGISTER_CLICK,
    LOGIN_PAGE_SIGN_IN_EMAIL_CLICK,
    RESET_PASSWORD_PAGE_RESET_PASSWORD_CLICK,
    FORGOT_PASSWORD_PAGE_MAGIC_LINK_CLICK,
    FORGOT_PASSWORD_PAGE_MAGIC_LINK_SENT,
    PASSWORD_RECOVERY_INFO_PAGE_MISSING_MAGIC_LINK_CLICK,
    PASSWORD_RECOVERY_INFO_PAGE_CHECK_EMAIL_VIEW,
    PASSWORD_RECOVERY_INFO_PAGE_BACK_TO_HOME_CLICK,
    MISSING_MAGIC_LINK_PAGE_VIEW,
} from '@analytics-types/Events';
import {
    USER_LOGIN_LABEL_FACEBOOK,
    USER_LOGIN_LABEL_GOOGLE,
} from '@analytics-types/Analytics';
import { MODULE_NAME as CLIENT_MODULE_NAME } from '@analytics-modules/client/meta';
import { MISSING_MAGIC_LINK_MODAL_BACK_TO_LOGIN_CLICK } from '@analytics-modules/client/types/Events';

import AuthorizationForms from '@organisms/AuthorizationForms/AuthorizationForms';

import {
    CUSTOMER_ACCOUNT_INVITE_FIEND_NAME,
    LOGIN_PAGE_NAME,
    REGISTER_PAGE_NAME,
    RESET_PASSWORD_PAGE_NAME,
    CUSTOMER_PAGE_NAME,
} from '@router/names';

import { getPathBackToShopping } from '@assets/login-register';
import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

import BasePage from '@pages/mixins/BasePage';

const {
    mapState: mapCustomerState,
    mapActions: mapCustomerActions,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');
const { mapActions: mapCartActions } = createNamespacedHelpers('cart');
const { mapState: mapAvatarState } = createNamespacedHelpers('avatar');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapState: mapModalsState } = createNamespacedHelpers('modals');

export default {
    name: 'LoginRegister',

    components: {
        AuthorizationForms,
    },

    mixins: [BasePage(), AnalyticsEvents],

    layout: () => 'login-register',

    async asyncData({ app, route, from }) {
        const descriptionArray = [
            app.$t('Top brands and top manufacturers'),
            app.$t('Free delivery and {days} days return', {
                days: app.$getLocaleConfigByKey(DAYS_FOR_RETURNS),
            }),
            app.$t('Online fashion at Eobuwie'),
        ];

        const titleMap = {
            [LOGIN_PAGE_NAME]: 'Login',
            [REGISTER_PAGE_NAME]: 'Register',
            [RESET_PASSWORD_PAGE_NAME]: 'Reset password',
        };

        return {
            meta: {
                title: app.$t(titleMap[route.name]),
                description: descriptionArray.join(' | '),
                robots: META_ROBOTS_INDEX_FOLLOW,
            },

            initialEmail: '',

            fromPage: from,
        };
    },

    head() {
        const {
            meta: { title, description, robots },
        } = this;

        return {
            title,
            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: this.$getMetaTagRobotsContent(robots),
                },
                {
                    hid: 'title',
                    name: 'title',
                    content: title,
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: description,
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: title,
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: description,
                },
            ],
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapAvatarState(['isInviteFriendFeatureEnabled']),
        ...mapCustomerGetters(['isLoggedIn']),
        ...mapConfigGetters(['isAccountVerificationEnabled', 'storeCode']),

        ...mapModalsState(['shouldOpenSniperLinkModal']),
        ...mapCustomerState(['shouldOpenModalNewsletterConfirmation']),

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        isModivoClubEnabled() {
            return isModivoClubTestEnabled(this.$abTests);
        },

        withRegisterBenefits() {
            return (
                this.isMobile &&
                this.currentFormId === AUTHORIZATION_FORMS_IDS.REGISTER
            );
        },

        currentFormId() {
            return this.formsByPageName[this.$route.name];
        },

        formsByPageName() {
            const {
                LOGIN,
                REGISTER,
                FORGOT_PASSWORD,
            } = AUTHORIZATION_FORMS_IDS;

            return {
                [LOGIN_PAGE_NAME]: LOGIN,
                [REGISTER_PAGE_NAME]: REGISTER,
                [RESET_PASSWORD_PAGE_NAME]: FORGOT_PASSWORD,
            };
        },

        pathBackToShopping() {
            return getPathBackToShopping(this.fromPage?.path);
        },

        authorizationFormsListeners() {
            return {
                'register-success': $event => this.registerSuccess($event),
                'set-form-id': $event => this.navigateToFormId($event),
                'login-success': () => this.redirectBackToShopping(),
                'reset-password-success': this.resetPasswordBack,
                'reset-password-back-click': () => this.resetPasswordBack(true),
                'social-login-click': $event => this.onSocialLoginClick($event),
                'reset-password-submit': () =>
                    this.handleClickEvent(
                        RESET_PASSWORD_PAGE_RESET_PASSWORD_CLICK
                    ),

                'register-click': () =>
                    this.handleClickEvent(REGISTER_PAGE_REGISTER_CLICK),

                'login-click': () =>
                    this.handleClickEvent(LOGIN_PAGE_SIGN_IN_EMAIL_CLICK),

                'click-send-magic-link-button': () =>
                    this.handleClickEvent(
                        FORGOT_PASSWORD_PAGE_MAGIC_LINK_CLICK
                    ),

                'magic-link-sent': () =>
                    this.$analytics.emit(FORGOT_PASSWORD_PAGE_MAGIC_LINK_SENT),

                'magic-link-missing-link-click': () =>
                    this.handleClickEvent(
                        PASSWORD_RECOVERY_INFO_PAGE_MISSING_MAGIC_LINK_CLICK
                    ),

                'magic-link-show-sent-information': () =>
                    this.$analytics.emit(
                        PASSWORD_RECOVERY_INFO_PAGE_CHECK_EMAIL_VIEW
                    ),

                'magic-link-go-to-home': () =>
                    this.handleClickEvent(
                        PASSWORD_RECOVERY_INFO_PAGE_BACK_TO_HOME_CLICK
                    ),

                'magic-link-back-to-login': () => {
                    this.$analytics.moduleEmit(
                        CLIENT_MODULE_NAME,
                        MISSING_MAGIC_LINK_MODAL_BACK_TO_LOGIN_CLICK
                    );

                    this.navigateToFormId(AUTHORIZATION_FORMS_IDS.LOGIN, false);
                },

                'magic-link-show-missing-link-information': () =>
                    this.$analytics.emit(MISSING_MAGIC_LINK_PAGE_VIEW),
            };
        },
    },

    async mounted() {
        this.initialEmail = await this.getEmailFromMagicLinkQuery();

        this.shouldFetchPaymentAndShippingAfterLogin();

        await this.isCustomerFetchedDeferred.promise;

        if (this.isLoggedIn) {
            if (this.initialEmail && this.$route.name === REGISTER_PAGE_NAME) {
                await this.logout({ showSuccessMessage: false });

                return;
            }

            this.$router.push({ name: CUSTOMER_PAGE_NAME });
        }
    },

    methods: {
        ...mapCustomerActions(['login', 'logout']),
        ...mapCartActions(['setFetchingPaymentAndShippingAfterLogin']),

        async getEmailFromMagicLinkQuery() {
            if (!window) {
                return '';
            }

            try {
                const encodedEmail = this.$route.query.magicLinkRegister || '';

                const query = { ...this.$route.query };

                delete query.magicLinkRegister;

                await this.$router.replace({ query });

                return window.atob(encodedEmail);
            } catch {
                return '';
            }
        },

        getPageNameByFormId(formId) {
            const { formsByPageName } = this;

            return (
                Object.keys(formsByPageName).find(
                    pageName => formsByPageName[pageName] === formId
                ) || ''
            );
        },

        onSocialLoginClick(loginMethod) {
            const isRegisterPage = this.$route.name === REGISTER_PAGE_NAME;

            const eventsMapByLoginMethod = {
                [USER_LOGIN_LABEL_FACEBOOK]: isRegisterPage
                    ? REGISTER_PAGE_CONTINUE_WITH_FACEBOOK_CLICK
                    : LOGIN_PAGE_CONTINUE_WITH_FACEBOOK_CLICK,

                [USER_LOGIN_LABEL_GOOGLE]: isRegisterPage
                    ? REGISTER_PAGE_CONTINUE_WITH_GOOGLE_CLICK
                    : LOGIN_PAGE_CONTINUE_WITH_GOOGLE_CLICK,
            };

            const event = eventsMapByLoginMethod[loginMethod];

            if (event) {
                this.$analytics.emit(event);
            }
        },

        resetPasswordBack(withClickEvent = false) {
            const event = withClickEvent
                ? RESET_PASSWORD_PAGE_BACK_TO_LOGIN_PAGE_CLICK
                : RESET_PASSWORD_PAGE_RESET_PASSWORD_SENT;

            this.$analytics.emit(event);

            this.navigateToFormId(AUTHORIZATION_FORMS_IDS.LOGIN, false);
        },

        redirectBackToShopping() {
            if (this.isInviteFriendFeatureEnabled) {
                const shouldRedirectToInviteFriendPage = this.$storage.getItem(
                    REDIRECT_TO_INVITE_FRIEND_PAGE_AFTER_LOGIN
                );

                if (shouldRedirectToInviteFriendPage) {
                    this.$storage.removeItem(
                        REDIRECT_TO_INVITE_FRIEND_PAGE_AFTER_LOGIN
                    );

                    this.$router.push({
                        name: CUSTOMER_ACCOUNT_INVITE_FIEND_NAME,
                    });

                    return;
                }
            }

            if (this.isAccountVerificationEnabled) {
                const shouldRedirectToCustomerPage = this.$storage.getItem(
                    REDIRECT_TO_CUSTOMER_PAGE_AFTER_LOGIN
                );

                if (shouldRedirectToCustomerPage) {
                    this.$storage.removeItem(
                        REDIRECT_TO_CUSTOMER_PAGE_AFTER_LOGIN
                    );

                    this.$router.push({
                        name: CUSTOMER_PAGE_NAME,
                    });

                    return;
                }
            }

            this.$router.push({ path: this.pathBackToShopping });
        },

        async registerSuccess({
            email,
            password,
            type,
            showJoinToLoyaltyClubModal,
            isUserJoinedToLoyaltyClub,
        }) {
            const success = await this.login({ email, password });

            if (success) {
                this.$analytics.emit(USER_LOGIN_SUCCESS, {
                    email,
                    type,
                });

                if (this.isLoyaltyClubEnabled) {
                    if (showJoinToLoyaltyClubModal) {
                        this.$modals.open(JOIN_TO_LOYALTY_CLUB_MODAL);
                    }

                    if (isUserJoinedToLoyaltyClub) {
                        this.$loyaltyPubSub?.publish(
                            TOPICS.LOYALTY_CLUB_SIGN_IN
                        );
                    }
                }

                if (
                    this.isAccountVerificationEnabled &&
                    !this.shouldOpenSniperLinkModal &&
                    !this.shouldOpenModalNewsletterConfirmation &&
                    !this.isModivoClubEnabled
                ) {
                    this.$modals.open(MODAL_ACCOUNT_VERIFICATION, {
                        [MODAL_ACCOUNT_VERIFICATION_TYPE]:
                            ACCOUNT_VERIFICATION_STATES.PENDING,
                    });
                }

                if (this.shouldOpenSniperLinkModal) {
                    this.$modals.open(MODAL_SNIPER_LINK, {
                        [MODAL_SNIPER_LINK_EMAIL]: email,
                    });
                } else if (this.shouldOpenModalNewsletterConfirmation) {
                    this.$modals.open(MODAL_NEWSLETTER_CONFIRMATION);
                }

                this.redirectBackToShopping();
            }
        },

        navigateToFormId(formId, withGAEvent = true) {
            if (withGAEvent) {
                const {
                    LOGIN,
                    REGISTER,
                    FORGOT_PASSWORD,
                } = AUTHORIZATION_FORMS_IDS;

                const EVENTS = {
                    [LOGIN]: REGISTER_PAGE_LOGIN_CLICK,
                    [REGISTER]: LOGIN_PAGE_REGISTER_CLICK,
                    [FORGOT_PASSWORD]: LOGIN_PAGE_RESET_PASSWORD_CLICK,
                };

                this.handleClickEvent(EVENTS[formId]);
            }

            this.$router.push(this.getPageNameByFormId(formId));
        },

        shouldFetchPaymentAndShippingAfterLogin() {
            if (
                PAYMENT_AND_SHIPPING_METHODS_WHITELIST.includes(
                    this.fromPage?.name
                )
            ) {
                this.setFetchingPaymentAndShippingAfterLogin(true);
            }
        },
    },
};
</script>
